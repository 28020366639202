import { LeadModel, ValidationModel } from '@model';
import { Validators } from '@src/lib';
import { UserDataOptions } from '@src/user-data';

export const UserDataConfig: UserDataOptions = {
  persistence: 'session',
  valuesKey: 'userDetails',
  errorsKey: 'formErrors',
  validators: {
    email: async (values: Partial<LeadModel>): Promise<ValidationModel> => {
      const { email } = values;
      return Validators.email(email);
    },
    title: (values: Partial<LeadModel>): ValidationModel => {
      const { title } = values;

      return Validators.required(title);
    },
    firstName: (values: Partial<LeadModel>): ValidationModel => {
      const { firstName, lastName } = values;

      return Validators.firstNameLastName(firstName, lastName);
    },
    middleName: (values: Partial<LeadModel>): ValidationModel => {
      const { middleName } = values;
      return Validators.middleName(middleName);
    },
    lastName: (values: Partial<LeadModel>): ValidationModel => {
      const { firstName, lastName } = values;

      return Validators.firstNameLastName(lastName, firstName);
    },
    telephone: (values: Partial<LeadModel>): ValidationModel => {
      const { telephone } = values;

      return Validators.ukAndNationalPhoneNumber(telephone.raw);
    },
    dob: (values: Partial<LeadModel>): ValidationModel => {
      const { dob } = values;
      return Validators.dob(dob);
    },
    address: (values: Partial<LeadModel>): ValidationModel => {
      const {
        address: { line1, line2, town, postcode },
      } = values;

      return Validators.address({
        line1,
        line2,
        town,
        postcode,
      });
    },
    signature: (values: Partial<LeadModel>): ValidationModel => {
      const { signature } = values;

      return Validators.required(signature);
    },
  },
}
