// Mute TS About roptoSession in Window
declare const window: Window & { roptoSession: any };

const EMAIL_VALIDATION_URL =
  process.env.STAGE !== 'production'
    ? 'https://valid-dev.ropto.com/email-mx-and-address'
    : 'https://valid.ropto.com/email-mx-and-address';

export const emailRegex = /^((?!\.{2,}|^\.|\.@)[a-zA-Z0-9._%+-])+@[a-z0-9.-]+\.[a-z]{2,12}$/;

async function emailAdvancedValidation(email) {
  const parsedEmail = email.replace(/\s/g, '');

  try {
    const response = await fetch(`${EMAIL_VALIDATION_URL}/${parsedEmail}`);

    if (!response) {
      throw Error('Failed to validate email: no response received');
    }

    if (response.status < 200 || response.status >= 300) {
      console.log('Response: ', JSON.stringify({ ...response }));

      throw new Error('Failed to validate email: status code not in 2xx range');
    }

    return response.json();
  } catch (e) {
    if (window.roptoSession) {
      window.roptoSession.sendSessionEvent({
        action: 'FAIL',
        label: 'EMAIL VALIDATION FAIL',
        value: e,
      });
    }
    return { success: true };
  }
}

export { emailAdvancedValidation };
