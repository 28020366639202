export enum ReferrerEnum {
  TESTING = 2,
  ROPTO = 4,
  TSL_MEDIA = 5,
  ADVANTAGE_MARKETING = 6,
  ZURU = 7,
  WYBE = 8,
  TCG = 9,
  TCGL = 10,
  CLOCKWORKS = 11,
}
