export enum VictaProductEnum {
  UNIFORM = 'UNIFT',
  WFH = 'WFHT',
  PPI = 'PPIT',
  MARRIAGE_TAX = 'MT',
  PLEVIN = 'PLEV',
  UNDISCLOSED_COMMISSION = 'UC',
  UNAFFORDABLE_LENDING = 'UL',
  LENDING = 'LEND',
}
