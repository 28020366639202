import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { debounceTime, distinctUntilChanged, pairwise } from 'rxjs/operators';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LeadModel, ValidationModel } from '@model';
import { diff } from '@src/lib/utils';
import { UserDataContext } from './context';
import { clearStorageKey, getStorageKey, setStorageKey } from './storage';
import { UserDataProps } from './interfaces';

export function UserData(props: PropsWithChildren<UserDataProps>) {
  const {options: { persistence, valuesKey, errorsKey, validators }} = props;
  const [ valueSubject ] = useState<BehaviorSubject<Partial<LeadModel>>>(new BehaviorSubject<Partial<LeadModel>>(getStorageKey({
    key: valuesKey,
    persistence
  })));
  const [activeChangeSubscription, setActiveChangeSubscription] = useState(Subscription.EMPTY);
  const [values, setValues] = useState<Partial<LeadModel>>(getStorageKey({
    key: valuesKey,
    persistence
  }) as Partial<LeadModel>);
  const [errors, setErrors] = useState<{ [key in keyof LeadModel]: ValidationModel }>(getStorageKey({
    key: errorsKey,
    persistence
  }) as { [key in keyof LeadModel]: ValidationModel });

  // Validate all changes asynchronously at one pass without blocking the Event Loop
  const validateChanges = async (changes: Partial<LeadModel>, allValues): Promise<void> => {
    const errorsOfChanges = {};

    await Promise.all(
      Object.keys(changes).map(async (fieldName) => {
        const validatorFunction = validators[fieldName];

        if (validatorFunction) {
          errorsOfChanges[fieldName] = await validatorFunction(allValues);
        }
      }),
    ).then(() => {
      const existingErrros = JSON.parse(sessionStorage.getItem(errorsKey));
      setErrors({ ...existingErrros, ...errorsOfChanges });
    });
  };

  // update storage and validation on value change
  useEffect(() => {
    setStorageKey({
      key: valuesKey,
      value: values,
      persistence,
    });
  }, [values]);

  // update storage on errors change
  useEffect(() => {
    setStorageKey({
      key: errorsKey,
      value: errors,
      persistence,
    })
  }, [errors])

  // update values with new data
  const updateData = (newValues: Partial<LeadModel>): void => {
    const combinedValues = {
      ...getStorageKey({ key: valuesKey, persistence }),
      ...newValues
    };

    setValues(combinedValues)

    return valueSubject.next(combinedValues);
  };

  useEffect(()=> {
    if (activeChangeSubscription === Subscription.EMPTY) {
      const sub = valueSubject.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        pairwise(),
      ).subscribe(async ([oldValues, newValues]) => validateChanges(diff(oldValues, newValues), valueSubject.value));

      setActiveChangeSubscription(sub);
    }
  }, [])

  // Reset values to empty object
  const clear = (): void => {
    clearStorageKey({
      key: valuesKey, persistence
    })
    clearStorageKey({
      key: errorsKey, persistence
    })
    setValues({});
    setErrors({} as { [key in keyof LeadModel]: ValidationModel });
    valueSubject.next({})
  };

  const ctxValue = useMemo(() => ({
      updateData,
      clear,
      values,
      errors,
    }
  ), [values, errors]);

  // return a Wrapper Provider with ctxValue as its value
  // every property included in ctxValue will be available on useContext
  return <UserDataContext.Provider value={ctxValue} {...props} />;
}
