'use strict';

exports.onRouteUpdate = function (_ref) {
  var location = _ref.location;

  // Don't track while developing.
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer = window.dataLayer || [];
    window.uetq = window.uetq || [];
    window.uetq.push('event', 'page_view', { page_path: location.pathname });
    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(function () {
      window.dataLayer.push({
        event: "gatsby-route-change"
      });
    }, 50);

    // Facebook route change and lead events
    if (typeof fbq === 'function') {
      fbq('track', 'PageView');
    }
  }
};
