import { ApiService } from '@src/service';
import { LeadEventModel } from '@src/model/interface/lead-event.model';
import { MdsOptInModel, OptInModel } from '@model';
import { isBrowser, parseUrlParams } from '@src/lib';

interface MdsInitConfig {
  baseUrl: string;
  opts: { clientId: string };
  storageKey: string;
  /** Individual for each campaign ... */
  resumeKeyKey: string;
  initHeaders?: HeadersInit;
}

export class MdsService extends ApiService {
  private storageKey: string;
  private leadId: string;
  private email: string;
  private resumeKeyKey: string;

  constructor(args: MdsInitConfig) {
    const { baseUrl, opts, storageKey, resumeKeyKey = 'resumeKey', initHeaders } = args;

    super({ baseUrl, opts, initHeaders, serviceName: 'MDS-Service' });

    this.resumeKeyKey = resumeKeyKey;
    this.storageKey = storageKey;
    this.leadId = this.getExistingLeadId();
  }

  getExistingLeadId(): string | null {
    if (isBrowser) {
      return sessionStorage.getItem(this.storageKey)?.toString();
    }

    return null;
  }

  async initLeadId(mdsOptInObj: MdsOptInModel): Promise<void> {
    const { introducer } = parseUrlParams();
    this.email = mdsOptInObj.email;

    try {
      const body = {
        ...mdsOptInObj,
        [this.resumeKeyKey]: mdsOptInObj.resumeKey,
        ...this.sessionValues,
        clientId: '1',
        productId: mdsOptInObj.productId,
        introducer: introducer || '6'
      };

      delete body.resumeKey;

      const { leadId } = await this.post<{ leadId: string }>(`${this.environment}/leads`, body);

      this.logger(`Init LeadID - [${leadId}] for [${mdsOptInObj.email}]`);

      this.leadId = leadId;

      if (isBrowser) {
        sessionStorage.setItem(this.storageKey, this.leadId.toString());
      }
    } catch (e) {
      this.logger('Lead ID Init Failed with Error: ', e);
    }
  }

  async updateGoal(body: LeadEventModel): Promise<void> {
    if (!this.leadId) {
      this.logger('MISSING LEAD ID! INVOKE `initLead` First');
      return Promise.resolve();
    }

    return this.post<void>(`${this.environment}/leads/${this.leadId}/goal`, {
      ...body,
      goal: body.goal || 'UK PCP: PCP Claims',
      email: body.email,
    });
  }

  async addInterests(interests: string[]): Promise<void> {
    const marketingPreferences = {
      credit_products: interests.includes('Credit products'),
      debt_help: interests.includes('Debt help'),
      insurance: interests.includes('Insurance'),
      utilities: interests.includes('Utility switching'),
      legal_services: interests.includes('Legal services'),
    };

    const mappedMarketingPreferences: OptInModel[] = Object.keys(marketingPreferences).map((key) => ({
      status: !!marketingPreferences[key],
      type: 'hard',
      interest: key,
    }));

    const payload = {
      productId: this.sessionValues.productId,
      optIns: mappedMarketingPreferences,
    };
    return this.post<void>(`${this.environment}/leads/${this.leadId}/interests`, payload);
  }
}
