enum IncomeBracketEnum {
  lessOr11500 = '£11,500 or less',
  lessOr11850 = '£11,850 or less',
  lessOr12500 = '£12,500 or less',
  lessOr12570 = '£12,570 or less',
  between11501And43500 = '£11,501 - £43,500',
  between11851And46350 = '£11,851 - £46,350',
  between12501And50000 = '£12,501 - £50,000',
  between12571And50270 = '£12,571 - £50,270',
  moreOr43501 = '£43,501 or more',
  moreOr46350 = '£46,350 or more',
  moreOr50001 = '£50,001 or more',
  moreOr50271 = '£50,271 or more',
  unemployed = 'unemployed',
}

export default IncomeBracketEnum;
export { IncomeBracketEnum };
