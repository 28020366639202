import { navigate } from 'gatsby';
import { HLocation, NavigateOptions } from '@reach/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { BehaviorSubject, Subscription } from 'rxjs';

// Mute TS About roptoSession in Window
declare const window: Window & { dataLayer: any };

export const formatBytes= (bytes: number, decimals: number = 0) => {
  let units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  
  let i: number = 0
  
  for (i; bytes > 1024; i++) {
    bytes /= 1024;
  }
  
  return parseFloat(bytes.toFixed(decimals)) + units[i]
}

function useObservable<T>(behaviorSubject: BehaviorSubject<T>): [T, (val: T) => void] {
  try {
    const [value, setValue] = useState<T>(behaviorSubject.value);

    useEffect(() => {
      const sub: Subscription = behaviorSubject.subscribe(setValue);

      // This is the TEARDOWN of the Observable - Removing this will cause memory leak
      return () => sub.unsubscribe();
    }, []);

    return [value, (value: T) => behaviorSubject.next(value)];
  } catch (e) {
    console.error('You cannot use this Hook outside of a component');
    throw e;
  }
}

function generateUUID() {
  let d = new Date().getTime();
  let d2 = (typeof performance !== `undefined` && performance.now && performance.now() * 1000) || 0;
  return `xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx`.replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === `x` ? r : (r & 0x3) | 0x8).toString(16);
  });
}

const isBrowser = typeof window !== 'undefined';

const inferAddressValues = (obj) => {
  // Create required values from manual addresses
  const { address, fullAddress, county, line1, line2, town, postcode } = obj;

  const inferredAddress = [line1, line2, town].join(` `);
  if (obj && Object.keys(obj).length) {
    return {
      ...obj,
      county: county || town,
      address: address || inferredAddress,
      fullAddress: fullAddress || `${inferredAddress} ${postcode}`,
    };
  }
  return obj;
};

const parseUrlParams = () => {
  if (isBrowser) {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
  }

  return {};
};

const sortObjectKeys = (unordered: any) =>
  Object.keys(unordered)
    .sort()
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = unordered[key];
      return obj;
    }, {});

const navigateWithSearchParams = <TState, T>(
  pagePath: string,
  location: HLocation<T | unknown>,
  opts?: NavigateOptions<TState | unknown>,
) => navigate(`${pagePath}${location.search}`, opts);

const usePersistedState = <T>(
  key: string,
  defaultValue: T,
  storage: 'sessionStorage' | 'localStorage' = 'sessionStorage',
): [T, Dispatch<SetStateAction<T>>] => {
  if (!isBrowser) {
    return [defaultValue, () => {}];
  }

  const store = window[storage];

  if (!store) {
    return null;
  }

  const [state, setState] = useState<T>(() => JSON.parse(store.getItem(key)) || defaultValue);

  useEffect(() => {
    store.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

const diff = (o1: unknown, o2: unknown): any =>
  Object.keys(o2).reduce((differences, key) => {
    if (JSON.stringify(o1[key]) === JSON.stringify(o2[key])) {
      return differences;
    }

    return { ...differences, [key]: o2[key] };
  }, {});


export function navigateBack() {
  return window?.history.back();
}

export function environmentStage() {
  return process.env.STAGE !== 'production' ? 'dev' : 'prod'
}

export {
  useObservable,
  generateUUID,
  isBrowser,
  inferAddressValues,
  parseUrlParams,
  sortObjectKeys,
  navigateWithSearchParams,
  usePersistedState,
  diff,
};
