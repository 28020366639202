export const industryList: { label: string | number; value: number | string; }[] = [
  { label: 'Agriculture', value: 1 },
  { label: 'Airlines', value: 2 },
  { label: 'Aluminium', value: 3 },
  { label: 'Armed forces', value: 4 },
  { label: 'Banks and building societies', value: 5 },
  { label: 'Brass and copper', value: 6 },
  { label: 'Building', value: 7 },
  { label: 'Building materials', value: 8 },
  { label: 'Clothing', value: 9 },
  { label: 'Constructional engineering', value: 10 },
  { label: 'Electrical and electricity supply', value: 11 },
  { label: 'Trades ancillary to engineering', value: 12 },
  { label: 'Fire service', value: 13 },
  { label: 'Food', value: 14 },
  { label: 'Forestry', value: 15 },
  { label: 'Glass', value: 16 },
  { label: 'Healthcare', value: 17 },
  { label: 'Heating', value: 18 },
  { label: 'Iron mining', value: 19 },
  { label: 'Iron and steel', value: 20 },
  { label: 'Leather', value: 21 },
  { label: 'Factory Work', value: 22 },
  { label: 'Police force', value: 23 },
  { label: 'Precious metals', value: 24 },
  { label: 'Printing', value: 25 },
  { label: 'Prisons', value: 26 },
  { label: 'Public service - docks and inland waterways', value: 27 },
  { label: 'Public service - public transport', value: 28 },
  { label: 'Quarrying', value: 29 },
  { label: 'Railways', value: 30 },
  { label: 'Seamen', value: 31 },
  { label: 'Shipyards', value: 32 },
  { label: 'Textiles and textile printing', value: 33 },
  { label: 'Vehicles', value: 34 },
  { label: 'Wood and furniture', value: 35 },
  { label: 'Unspecified', value: 36 },
  { label: 'Unemployed', value: 37 },
  { label: 'Retired', value: 38 },
];
export default industryList;
