import { ApiService } from '@src/service';
import { VictaProductEnum } from '@enum';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BehaviorSubject, retry, Subscription } from 'rxjs';
import { useObservable } from '@src/lib';

export interface ToeDetailsModel {
  id: number;
  url: string;
  version: string;
}

interface ToeInitConfig {
  baseUrl: string;
  opts: { product: VictaProductEnum };
  initHeaders?: HeadersInit;
}

export class LpServicesService extends ApiService {
  private readonly product: VictaProductEnum;
  private readonly subscription: Subscription = Subscription.EMPTY;
  private observableToeDetails: BehaviorSubject<ToeDetailsModel> = new BehaviorSubject({ id: 0, url: '', version: '' });

  get toe(): ToeDetailsModel {
    return this.observableToeDetails.value;
  }

  set toe(val: ToeDetailsModel) {
    this.observableToeDetails.next(val);
  }

  hookedToe = (): ToeDetailsModel => useObservable(this.observableToeDetails)[0];

  constructor(args: ToeInitConfig) {
    const { baseUrl, opts, initHeaders } = args;
    super({ baseUrl, opts: { clientId: '', ...opts }, initHeaders, serviceName: 'LpServices-Service' });

    this.product = opts.product;

    if (this.subscription === Subscription.EMPTY) {
      this.subscription = this.init(this.product);
    }
  }

  init(product: VictaProductEnum): Subscription {
    return fromPromise(this.get<ToeDetailsModel>(`${this.environment}/toe?product=${product}`))
      .pipe(retry({ count: 5, delay: 2000 }))
      .subscribe((toeDetails: ToeDetailsModel) => (this.toe = toeDetails));
  }
}
